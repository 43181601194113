import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Input,
  VStack,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

type FormValue = {
  title: string;
  isAutoPrefix: boolean;
};

interface CreateSubsectionFormProps {
  onSubmit: (data: FormValue) => void;
}

export default function CreateSubsectionForm({ onSubmit }: CreateSubsectionFormProps) {
  const { register, formState, errors, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      title: '',
      isAutoPrefix: true,
    },
  });

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing={4}>
        <FormControl textStyle="body2.default" isInvalid={errors?.title !== undefined}>
          <Input
            ref={register({
              validate: (value) => value.length > 0 || 'กรุณากรอกชื่อหัวข้อ',
              setValueAs: (value) => value.trim(),
            })}
            name="title"
            type="text"
            required
            borderRadius="100px"
            borderColor="gray.light.95"
            placeholder="กรุณากรอกชื่อหัวข้อ"
            _placeholder={{ color: 'gray.light.65' }}
          />
          <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
        </FormControl>
        <FormControl textStyle="body2.default">
          <Checkbox ref={register} size="lg" colorScheme="brand" name="isAutoPrefix">
            ใส่เลขหัวข้อ
          </Checkbox>
        </FormControl>
      </VStack>
      <Button
        type="submit"
        isLoading={formState.isSubmitting}
        width="full"
        mt={8}
        colorScheme="brand"
        textStyle="body1.default"
        borderRadius="100px"
      >
        เพิ่ม
      </Button>
    </Box>
  );
}
