import {
  ArrowUpIcon as ChakraArrowUpIcon,
  ArrowDownIcon as ChakraArrowDownIcon,
  EditIcon,
  DeleteIcon,
} from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonProps,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import { PropsWithChildren } from 'react';
import useSectionEditor from '../Hook/useSectionEditor';
import type { Basket, MediaItem, Section, Subsection as SubsectionData } from '../types';
import { getContents } from '../utils';
import AddOutlineIcon from './Icon/AddOutlineIcon';
import ArrowDownIcon from './Icon/ArrowDownIcon';
import ArrowUpIcon from './Icon/ArrowUpIcon';
import MediaCard from './MediaCard';

function AddItemButton(props: PropsWithChildren<Pick<ButtonProps, 'onClick'>>) {
  return (
    <Button
      // Apply `display: block` to the icon to avoid getting slightly bigger height than expected which occur because of the default `display: inline-block`
      leftIcon={<AddOutlineIcon display="block" boxSize="5" />}
      width="full"
      height="58px"
      colorScheme="brand"
      textStyle="body1.default"
      variant="outline"
      border="2px dashed"
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}

interface SectionProps {
  value: {
    prefix: string;
    title: string;
  };
  isFirst: boolean;
  isLast: boolean;
  isExpanded: boolean;
  onMoveSectionUp: () => void;
  onMoveSectionDown: () => void;
  onEditSection: () => void;
  onDeleteSection: () => void;
}

function SectionTitle({
  value,
  isFirst,
  isLast,
  isExpanded,
  onMoveSectionUp,
  onMoveSectionDown,
  onEditSection,
  onDeleteSection,
}: SectionProps) {
  const textStyle = useBreakpointValue({ base: 'body1.default', sm: 'h5' });
  return (
    <Box flex="1" textAlign="left">
      <Flex>
        <Center bgColor="brand.45" px={{ base: 6, sm: 8 }} py="18px">
          <Text textStyle={textStyle} color="text.dark1" whiteSpace="nowrap">
            {value.prefix}
          </Text>
        </Center>
        <Flex bgColor="brand.35" px={{ base: 4, sm: 8 }} py="18px" flexGrow={1} alignItems="center">
          <Text textStyle={textStyle} color="white">
            {value.title}
          </Text>
          <Spacer ml={4} />
          <HStack spacing={4}>
            <Tooltip
              hasArrow
              placement="top"
              label="เลื่อนขึ้น"
              background="gray.dark.15"
              textStyle="caption"
              px={4}
              py={2}
              color="white"
              borderRadius="4px"
            >
              <IconButton
                px={1}
                py={1}
                size="custom"
                icon={<ChakraArrowUpIcon />}
                aria-label="เลื่อนขึ้น"
                variant="unstyled"
                color="text.dark1"
                lineHeight="0.5em"
                isDisabled={isFirst}
                onClick={(event) => {
                  event.stopPropagation();
                  onMoveSectionUp();
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              placement="top"
              label="เลื่อนลง"
              background="gray.dark.15"
              textStyle="caption"
              px={4}
              py={2}
              color="white"
              borderRadius="4px"
            >
              <IconButton
                px={1}
                py={1}
                size="custom"
                icon={<ChakraArrowDownIcon />}
                aria-label="เลื่อนลง"
                variant="unstyled"
                color="text.dark1"
                lineHeight="0.5em"
                isDisabled={isLast}
                onClick={(event) => {
                  event.stopPropagation();
                  onMoveSectionDown();
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              placement="top"
              label="แก้ไขบทเรียน"
              background="gray.dark.15"
              textStyle="caption"
              px={4}
              py={2}
              color="white"
              borderRadius="4px"
            >
              <IconButton
                px={1}
                py={1}
                size="custom"
                icon={<EditIcon />}
                aria-label="แก้ไขบทเรียน"
                variant="unstyled"
                color="text.dark1"
                lineHeight="0.5em"
                onClick={(event) => {
                  event.stopPropagation();
                  onEditSection();
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              placement="top"
              label="ลบบทเรียน"
              background="gray.dark.15"
              textStyle="caption"
              px={4}
              py={2}
              color="white"
              borderRadius="4px"
            >
              <IconButton
                px={1}
                py={1}
                size="custom"
                icon={<DeleteIcon />}
                aria-label="ลบบทเรียน"
                variant="unstyled"
                color="text.dark1"
                lineHeight="0.5em"
                onClick={(event) => {
                  onDeleteSection();
                  event.stopPropagation();
                }}
              />
            </Tooltip>
            <Box p={1}>
              {isExpanded ? (
                <ArrowUpIcon display="block" boxSize={4} fill="none" color="text.dark1" />
              ) : (
                <ArrowDownIcon display="block" boxSize={4} fill="none" color="text.dark1" />
              )}
            </Box>
          </HStack>
        </Flex>
      </Flex>
    </Box>
  );
}

interface SubsectionProps {
  value: {
    prefix: string;
    title: string;
    contents: {
      id: number;
      type: string;
      value: any;
    }[];
  };
  isFirst: boolean;
  isLast: boolean;
  predicate: ({ category }: { category: string }) => boolean;
  onMoveSubsectionUp: () => void;
  onMoveSubsectionDown: () => void;
  onEditSubsection: () => void;
  onDeleteSubsection: () => void;
  onAddMediaItem: () => void;
  onMoveMediaItemUp: (data: { index: number }) => void;
  onMoveMediaItemDown: (data: { index: number }) => void;
  onRemoveMediaItem: (data: { mediaItemId: number }) => void;
}

function Subsection({
  value,
  isFirst,
  isLast,
  predicate,
  onMoveSubsectionUp,
  onMoveSubsectionDown,
  onEditSubsection,
  onDeleteSubsection,
  onAddMediaItem,
  onMoveMediaItemUp,
  onMoveMediaItemDown,
  onRemoveMediaItem,
}: SubsectionProps) {
  const textStyle = useBreakpointValue({ base: 'body1.default', sm: 'h5' });
  return (
    <AccordionItem border="none" mb={4}>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            _hover={{}}
            p={0}
            border="1px solid"
            borderColor="gray.light.95"
            borderRadius="8px"
            overflow="hidden"
          >
            <Box flex="1" textAlign="left">
              <Flex>
                <Box bgColor="brand.65" width={{ base: 2, sm: 4 }} />
                <Flex
                  bgColor="white"
                  pl={{ base: 6, sm: 8 }}
                  pr={{ base: 4, sm: 8 }}
                  py="18px"
                  flexGrow={1}
                  alignItems="center"
                >
                  <Text textStyle={textStyle} color="text.light1">
                    {value.prefix} {value.title}
                  </Text>
                  <Spacer ml={4} />
                  <HStack spacing={4}>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label="เลื่อนขึ้น"
                      background="gray.dark.15"
                      textStyle="caption"
                      px={4}
                      py={2}
                      color="white"
                      borderRadius="4px"
                    >
                      <IconButton
                        px={1}
                        py={1}
                        size="custom"
                        icon={<ChakraArrowUpIcon />}
                        aria-label="เลื่อนขึ้น"
                        variant="unstyled"
                        color="brand.45"
                        lineHeight="0.5em"
                        isDisabled={isFirst}
                        onClick={(event) => {
                          event.stopPropagation();
                          onMoveSubsectionUp();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label="เลื่อนลง"
                      background="gray.dark.15"
                      textStyle="caption"
                      px={4}
                      py={2}
                      color="white"
                      borderRadius="4px"
                    >
                      <IconButton
                        px={1}
                        py={1}
                        size="custom"
                        icon={<ChakraArrowDownIcon />}
                        aria-label="เลื่อนลง"
                        variant="unstyled"
                        color="brand.45"
                        lineHeight="0.5em"
                        isDisabled={isLast}
                        onClick={(event) => {
                          event.stopPropagation();
                          onMoveSubsectionDown();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label="แก้ไขหัวข้อ"
                      background="gray.dark.15"
                      textStyle="caption"
                      px={4}
                      py={2}
                      color="white"
                      borderRadius="4px"
                    >
                      <IconButton
                        px={1}
                        py={1}
                        size="custom"
                        icon={<EditIcon />}
                        aria-label="แก้ไขหัวข้อ"
                        variant="unstyled"
                        color="brand.45"
                        lineHeight="0.5em"
                        onClick={(event) => {
                          onEditSubsection();
                          event.stopPropagation();
                        }}
                      />
                    </Tooltip>
                    <Tooltip
                      hasArrow
                      placement="top"
                      label="ลบหัวข้อ"
                      background="gray.dark.15"
                      textStyle="caption"
                      px={4}
                      py={2}
                      color="white"
                      borderRadius="4px"
                    >
                      <IconButton
                        px={1}
                        py={1}
                        size="custom"
                        icon={<DeleteIcon />}
                        aria-label="ลบหัวข้อ"
                        variant="unstyled"
                        color="system.error"
                        lineHeight="0.5em"
                        onClick={(event) => {
                          onDeleteSubsection();
                          event.stopPropagation();
                        }}
                      />
                    </Tooltip>
                    <Box p={1}>
                      {isExpanded ? (
                        <ArrowUpIcon display="block" boxSize={4} fill="none" color="brand.45" />
                      ) : (
                        <ArrowDownIcon display="block" boxSize={4} fill="none" color="brand.45" />
                      )}
                    </Box>
                  </HStack>
                </Flex>
              </Flex>
            </Box>
          </AccordionButton>
          <AccordionPanel px={0} pt={4} pb={0}>
            <VStack spacing={4} align="stretch">
              {value.contents
                .filter(({ value }) => predicate(value))
                .map((content, index, contents) => (
                  <MediaCard
                    key={content.id}
                    value={content.value}
                    isFirst={index === 0}
                    isLast={index === contents.length - 1}
                    onMoveUp={() => onMoveMediaItemUp({ index })}
                    onMoveDown={() => onMoveMediaItemDown({ index })}
                    onRemoveMediaItem={onRemoveMediaItem}
                  />
                ))}
            </VStack>
            <Center justifyContent="stretch" mt={4}>
              <AddItemButton onClick={onAddMediaItem}>เพิ่มสื่อ</AddItemButton>
            </Center>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

function transformSubsections(currentSectionNo: number, index: number) {
  let currentSubsectionNo = 0;

  return (subsections: SubsectionData[]) => {
    return subsections.map((subsection, j_index) => {
      const key = `${currentSectionNo + index}.${j_index + 1}`;
      let subsectionPrefix = '';
      if (subsection.autoPrefix ?? true) {
        currentSubsectionNo++;
        subsectionPrefix = `${currentSectionNo + index}.${currentSubsectionNo}`;
      }

      const totalContents = getContents(subsection.contents).length;
      return {
        key,
        subsection,
        subsectionPrefix,
        j_index,
        totalContents,
      };
    });
  };
}

function transformSections() {
  let currentSectionNo = 1;
  return (sections: Section[]) => {
    return sections.map((section, index) => {
      if (section?.start !== undefined) {
        currentSectionNo = section.start;
      }
      return {
        section: {
          ...section,
          contents: transformSubsections(currentSectionNo, index)(section.contents),
        },
        totalContents: getContents(section.contents).length,
        sectionPrefix: `บทที่ ${currentSectionNo + index}`,
        index: index,
      };
    });
  };
}

export default function SectionAccordion({
  basket,
  onOpen,
}: {
  basket?: Basket;
  onOpen: () => void;
}) {
  const [{ sections }, dispatch] = useSectionEditor();

  const mediaItemsMap = new Map<number, any>(
    basket?.media_items.map((item: MediaItem) => [
      item.id,
      { ...item, basket: { title: basket.title } },
    ])
  );
  const transformedSections = transformSections()(sections);

  return (
    <Accordion
      py="8"
      allowToggle
      allowMultiple
      css={{
        '& .chakra-collapse': {
          margin: '-2rem -1rem',
          padding: '2rem 1rem',
          '@media screen and (min-width: 48em)': {
            margin: '-2rem -2rem',
            padding: '2rem 2rem',
          },
        },
      }}
      // index={expandedSections}
      // onChange={(expandedIndex) => {
      //   setExpandedSections(expandedIndex as number[]);
      // }}
    >
      {transformedSections?.map(({ section, totalContents, sectionPrefix, index }) => {
        return (
          <AccordionItem
            id={`section-${index}`}
            key={section.title}
            mb={4}
            border="none"
            _last={{ marginBottom: 0 }}
            css={{ scrollMarginTop: 134 /** Navbar height + Filter bar height */ }}
          >
            {({ isExpanded }) => (
              <>
                <AccordionButton _hover={{}} p={0} borderRadius="8px" overflow="hidden">
                  <SectionTitle
                    isFirst={index === 0}
                    isLast={index === sections.length - 1}
                    isExpanded={isExpanded}
                    value={{
                      prefix: sectionPrefix,
                      title: `${section.title} (${totalContents})`,
                    }}
                    onMoveSectionUp={() => {
                      dispatch({
                        type: 'moveSectionUp',
                        payload: {
                          sectionIndex: index,
                        },
                      });
                    }}
                    onMoveSectionDown={() => {
                      dispatch({
                        type: 'moveSectionDown',
                        payload: {
                          sectionIndex: index,
                        },
                      });
                    }}
                    onEditSection={() => {
                      dispatch({
                        type: 'startEditing',
                        payload: {
                          action: 'EDIT_SECTION',
                          sectionIndex: index,
                        },
                      });
                      onOpen();
                    }}
                    onDeleteSection={() => {
                      dispatch({
                        type: 'startEditing',
                        payload: {
                          action: 'DELETE_SECTION',
                          sectionIndex: index,
                        },
                      });
                      const isSectionEmpty = sections[index].contents.length === 0;
                      if (isSectionEmpty) {
                        dispatch({ type: 'deleteSection' });
                      } else {
                        onOpen();
                      }
                    }}
                  />
                </AccordionButton>
                <AccordionPanel p={0}>
                  <Accordion
                    pt={8}
                    pb={4}
                    allowToggle
                    allowMultiple
                    // index={expandedSubsections.get(index) ?? []}
                    // onChange={(expandedIndex) => {
                    //   const map = new Map(expandedSubsections);
                    //   map.set(index, expandedIndex as number[]);
                    //   setExpandedSubsections(map);
                    // }}
                  >
                    {section.contents.map(
                      ({ key, subsection, subsectionPrefix, j_index, totalContents }) => (
                        <Subsection
                          key={key}
                          isFirst={j_index === 0}
                          isLast={j_index === section.contents.length - 1}
                          value={{
                            prefix: subsectionPrefix,
                            title: `${subsection.title} (${totalContents})`,
                            contents: subsection.contents.map((content) => {
                              return {
                                ...content,
                                value: mediaItemsMap.get(content.id),
                              };
                            }),
                          }}
                          // predicate={selectedFilter.predicate}
                          predicate={() => true}
                          onMoveSubsectionUp={() =>
                            dispatch({
                              type: 'moveSubsectionUp',
                              payload: {
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            })
                          }
                          onMoveSubsectionDown={() =>
                            dispatch({
                              type: 'moveSubsectionDown',
                              payload: {
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            })
                          }
                          onEditSubsection={() => {
                            dispatch({
                              type: 'startEditing',
                              payload: {
                                action: 'EDIT_SUBSECTION',
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                            onOpen();
                          }}
                          onDeleteSubsection={() => {
                            dispatch({
                              type: 'startEditing',
                              payload: {
                                action: 'DELETE_SUBSECTION',
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                            const isSubsectionEmpty =
                              sections[index].contents[j_index].contents.length === 0;
                            if (isSubsectionEmpty) {
                              dispatch({ type: 'deleteSubsection' });
                            } else {
                              onOpen();
                            }
                          }}
                          onAddMediaItem={() => {
                            dispatch({
                              type: 'startEditing',
                              payload: {
                                action: 'ADD_MEDIA_ITEM',
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                            onOpen();
                          }}
                          onRemoveMediaItem={({ mediaItemId }) => {
                            dispatch({
                              type: 'removeMediaItem',
                              payload: {
                                mediaItemId: mediaItemId,
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                          }}
                          onMoveMediaItemUp={({ index: mediaItemIndex }) => {
                            dispatch({
                              type: 'moveMediaItemUp',
                              payload: {
                                mediaItemIndex,
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                          }}
                          onMoveMediaItemDown={({ index: mediaItemIndex }) => {
                            dispatch({
                              type: 'moveMediaItemDown',
                              payload: {
                                mediaItemIndex,
                                sectionIndex: index,
                                subsectionIndex: j_index,
                              },
                            });
                          }}
                        />
                      )
                    )}
                  </Accordion>
                  <Center justifyContent="stretch">
                    <AddItemButton
                      onClick={() => {
                        dispatch({
                          type: 'startEditing',
                          payload: {
                            action: 'CREATE_SUBSECTION',
                            sectionIndex: index,
                          },
                        });
                        onOpen();
                      }}
                    >
                      เพิ่มหัวข้อ
                    </AddItemButton>
                  </Center>
                  <Divider borderColor="gray.light.85" mt={8} mb={4} />
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        );
      })}
      <AccordionItem border="none">
        <Center justifyContent="stretch">
          <AddItemButton
            onClick={() => {
              dispatch({
                type: 'startEditing',
                payload: {
                  action: 'CREATE_SECTION',
                },
              });
              onOpen();
            }}
          >
            เพิ่มบทเรียน
          </AddItemButton>
        </Center>
      </AccordionItem>
    </Accordion>
  );
}
