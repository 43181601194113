import { ArrowUpIcon, ArrowDownIcon, DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  IconButton,
  Spacer,
  Tag,
  Text,
  Tooltip,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react';
import type { MediaItem } from '../types';
import { mapCategoryToLabel } from '../utils';
import Card from './Card';
import ViewIcon from './Icon/ViewIcon';
import Image from './Image';

const dateFormatter = new Intl.DateTimeFormat('th', {
  day: 'numeric',
  month: 'short',
  year: '2-digit',
});

type MediaCardProps = {
  value: MediaItem;
  isFirst: boolean;
  isLast: boolean;
  onMoveUp: () => void;
  onMoveDown: () => void;
  onRemoveMediaItem: (data: { mediaItemId: number }) => void;
};

function PlayOverlayIcon() {
  return (
    <Center
      position="absolute"
      top="calc(50% - 45px)"
      left="calc(50% - 45px)"
      bg="gray.light.75"
      borderRadius="100%"
      opacity="0.8"
      boxSize="90px"
    >
      <Box
        marginLeft="12px"
        borderLeft="34px solid"
        borderLeftColor="white"
        borderTop="19.5px solid"
        borderTopColor="transparent"
        borderBottom="19.5px solid"
        borderBottomColor="transparent"
      />
    </Center>
  );
}

export default function MediaCard(props: MediaCardProps) {
  const titleTextStyle = useBreakpointValue({ base: 'subtitle', sm: 'h5' });

  const { value: mediaItem, isFirst, isLast, onMoveUp, onMoveDown, onRemoveMediaItem } = props;

  return (
    <>
      <Card direction={{ base: 'column', sm: 'row' }} cursor="auto" _hover={{}} _active={{}}>
        <Box
          maxWidth={{ base: 'inherit', sm: '30%' }}
          position="relative"
          display="inline-block"
          flexShrink={0}
          css={{
            // Workaround for making Next Image (v10.0.5) has a min-height of 186px
            '& > div:not([class])': {
              display: 'block !important',
              height: '100%',
              minHeight: '186px',
            },
          }}
        >
          <Image
            src={`${process.env.REACT_APP_STATIC_URL}${mediaItem.coverImage?.url}`}
            alt={`รูปตัวอย่างสื่อ${mediaItem.title}`}
            htmlWidth="284"
            htmlHeight="186"
            width={{ base: 'full', sm: '284px' }}
            height={{ base: '186px', sm: 'full' }}
            minHeight="186px"
            maxHeight="204px"
            objectFit="cover"
            objectPosition={{ base: 'top', sm: 'top' }}
          />
          {mediaItem?.category === 'VIDEO' && <PlayOverlayIcon />}
          {mediaItem?.metadata && (
            <Box
              position="absolute"
              bottom="4"
              right="4"
              py="1"
              px="2"
              bg="gray.dark.25"
              borderRadius="4px"
              overflow="hidden"
              zIndex="docked"
            >
              <Text textStyle="subtitle" color="white">
                {mediaItem?.metadata}
              </Text>
            </Box>
          )}
        </Box>
        <Flex px={{ base: 4, sm: 8 }} py={4} width="full" direction="column">
          <Flex width="full">
            <VStack spacing={{ base: 1, sm: 2 }} align="flex-start">
              <Heading
                as="h5"
                textStyle={titleTextStyle}
                color="text.light1"
                isTruncated
                noOfLines={{ base: 0, sm: 2 }}
              >
                {mediaItem.title}
              </Heading>
              <Text as="span" textStyle="body1.default" color="text.light2">
                {mediaItem.basket.title}
              </Text>
            </VStack>
            <Spacer ml={4} />
            <HStack spacing={4} align="flex-start">
              <Tooltip
                hasArrow
                label="เลื่อนขึ้น"
                background="gray.dark.15"
                textStyle="caption"
                px={4}
                py={2}
                color="white"
                borderRadius="4px"
              >
                <IconButton
                  px={1}
                  py={1}
                  size="custom"
                  variant="unstyled"
                  color="brand.45"
                  borderColor="gray.light.85"
                  aria-label="เลื่อนขึ้น"
                  icon={<ArrowUpIcon display="block" />}
                  borderRadius="8px"
                  isDisabled={isFirst}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMoveUp();
                  }}
                />
              </Tooltip>
              <Tooltip
                hasArrow
                label="เลื่อนลง"
                background="gray.dark.15"
                textStyle="caption"
                px={4}
                py={2}
                color="white"
                borderRadius="4px"
              >
                <IconButton
                  px={1}
                  py={1}
                  size="custom"
                  variant="unstyled"
                  color="brand.45"
                  borderColor="gray.light.85"
                  aria-label="เลื่อนลง"
                  icon={<ArrowDownIcon display="block" />}
                  borderRadius="8px"
                  isDisabled={isLast}
                  onClick={(event) => {
                    event.stopPropagation();
                    onMoveDown();
                  }}
                />
              </Tooltip>
              <Tooltip
                hasArrow
                label="เอาสื่อออกจากหัวข้อ"
                background="gray.dark.15"
                textStyle="caption"
                px={4}
                py={2}
                color="white"
                borderRadius="4px"
              >
                <IconButton
                  px={1}
                  py={1}
                  size="custom"
                  variant="unstyled"
                  borderColor="gray.light.85"
                  aria-label="เอาสื่อออกจากหัวข้อ"
                  icon={<DeleteIcon display="block" />}
                  color="system.error"
                  borderRadius="8px"
                  onClick={(event) => {
                    event.stopPropagation();
                    onRemoveMediaItem({ mediaItemId: mediaItem.id });
                  }}
                />
              </Tooltip>
            </HStack>
          </Flex>
          <HStack mt={{ base: 1, sm: 2 }} spacing={4}>
            <Flex alignItems="center">
              <ViewIcon boxSize="8" fill="none" color="businessBlue.80" />
              <Text as="span" ml={1} textStyle="body2.default" color="businessBlue.80">
                {mediaItem.totalViews}
              </Text>
            </Flex>
            <Text as="span" textStyle="body2.default" color="businessBlue.80">
              แก้ไขล่าสุด {dateFormatter.format(new Date(mediaItem.updated_at))}
            </Text>
          </HStack>
          <Spacer mt={4} />
          <Flex width="full">
            <Tag
              px={4}
              py={1}
              textStyle="body2.default"
              borderRadius="100px"
              backgroundColor={`tag.${mediaItem.category.toLowerCase()}`}
              color={'white'}
            >
              {mapCategoryToLabel(mediaItem.category)}
            </Tag>
          </Flex>
        </Flex>
      </Card>
    </>
  );
}
