import * as React from 'react';
import { ChakraProvider, Box, Text, VStack, Grid, Center } from '@chakra-ui/react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import theme from './Theme';
import Editor from './Page/Editor';
import { QueryClient, QueryClientProvider } from 'react-query';
import { SectionEditorProvider } from './Hook/useSectionEditor';

const client = new QueryClient();

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <QueryClientProvider client={client}>
        <Router>
          <Switch>
            <Route path="/baskets/:slug">
              <SectionEditorProvider>
                <Editor />
              </SectionEditorProvider>
            </Route>
            <Route path="*">
              <Box textAlign="center" fontSize="xl">
                <Grid minH="100vh" p={3}>
                  <Center>
                    <VStack spacing={8}>
                      <Text textStyle="subtitle" fontFamily="Roboto">
                        Select the basket to edit its sections
                      </Text>
                    </VStack>
                  </Center>
                </Grid>
              </Box>
            </Route>
          </Switch>
        </Router>
      </QueryClientProvider>
    </ChakraProvider>
  );
}
