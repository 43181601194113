const fontWeights = {
  exLight: 200,
  light: 300,
  normal: 400,
  regular: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
};

const textStyles = {
  h1: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '60px',
    letterSpacing: '-0.5px',
    lineHeight: '70px',
  },
  h2: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '48px',
    letterSpacing: '-0.35px',
    lineHeight: '56px',
  },
  h3: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '34px',
    letterSpacing: '-0.25px',
    lineHeight: '44px',
  },
  h4: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '24px',
    letterSpacing: '0px',
    lineHeight: '32px',
  },
  h5: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '20px',
    letterSpacing: '0px',
    lineHeight: '28px',
  },
  subtitle: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.medium,
    fontSize: '18px',
    letterSpacing: '0.1px',
    lineHeight: '26px',
  },
  body1: {
    default: {
      fontFamily: 'Anuphan',
      fontWeight: fontWeights.regular,
      fontSize: '16px',
      letterSpacing: '0.1px',
      lineHeight: '24px',
    },
    readable: {
      fontFamily: 'Bai Jamjuree',
      fontWeight: fontWeights.regular,
      fontSize: '16x',
      letterSpacing: '-0.15px',
      lineHeight: '28px',
    },
    medium: {
      fontFamily: 'Anuphan',
      fontWeight: fontWeights.medium,
      fontSize: '16px',
      letterSpacing: '0.1px',
      lineHeight: '24px',
    },
  },
  body2: {
    default: {
      fontFamily: 'Anuphan',
      fontWeight: fontWeights.regular,
      fontSize: '14px',
      letterSpacing: '0.2px',
      lineHeight: '20px',
    },
    medium: {
      fontFamily: 'Anuphan',
      fontWeight: fontWeights.medium,
      fontSize: '14px',
      letterSpacing: '0.2px',
      lineHeight: '20px',
    },
  },
  caption: {
    fontFamily: 'Anuphan',
    fontWeight: fontWeights.regular,
    fontSize: '12px',
    letterSpacing: '0.5px',
    lineHeight: '18px',
  },
};

export default textStyles;
