import { Search2Icon, SmallCloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import useSectionEditor, { EditAction } from '../Hook/useSectionEditor';
import type { MediaItem } from '../types';
import { mapCategoryToLabel } from '../utils';
import CreateSectionForm from './CreateSectionForm';
import CreateSubsectionForm from './CreateSubsectionForm';
import EditSectionForm from './EditSectionForm';
import EditSubsectionForm from './EditSubsectionForm';
interface SearchMediaItemFormProps {
  onSubmit: (data: { query: string }) => void;
}

function SearchMediaItemForm({ onSubmit }: SearchMediaItemFormProps) {
  const { register, formState, errors, handleSubmit } = useForm<{ query: string }>();

  return (
    <Flex as="form" onSubmit={handleSubmit(onSubmit)} noValidate>
      <FormControl textStyle="body2.default" isRequired isInvalid={errors?.query !== undefined}>
        <InputGroup>
          <InputLeftElement children={<Search2Icon color="brand.45" />} />
          <Input
            ref={register({
              validate: (value) => value?.length > 0 || 'กรุณากรอกชื่อของสื่อที่คุณต้องการเพิ่ม',
              setValueAs: (value) => value.trim(),
            })}
            name="query"
            type="text"
            inputMode="search"
            enterKeyHint="search"
            required
            borderRadius="100px"
            borderColor="gray.light.95"
            placeholder="ชื่อสื่อที่คุณต้องการค้นหา"
            _placeholder={{ color: 'gray.light.65' }}
          />
        </InputGroup>
        <FormErrorMessage textStyle="caption" color="system.error">
          {errors?.query?.message}
        </FormErrorMessage>
      </FormControl>
      <Button
        ml={4}
        flex="0 0 auto"
        type="submit"
        isLoading={formState.isSubmitting}
        colorScheme="brand"
        textStyle="body1.default"
        borderRadius="100px"
      >
        ค้นหา
      </Button>
    </Flex>
  );
}

const EDIT_MODAL_HEADER_LABEL: Record<EditAction, string> = {
  CREATE_SECTION: 'เพิ่มบทเรียน',
  EDIT_SECTION: 'แก้ไขบทเรียน',
  DELETE_SECTION: 'คำเตือน',
  CREATE_SUBSECTION: 'เพิ่มหัวข้อ',
  EDIT_SUBSECTION: 'แก้ไขหัวข้อ',
  DELETE_SUBSECTION: 'คำเตือน',
  ADD_MEDIA_ITEM: 'เพิ่มสื่อ',
};

interface EditorModalProps extends Pick<ModalProps, 'isOpen' | 'onClose'> {
  mediaItems: MediaItem[];
}

export default function EditorModal({ mediaItems, isOpen, onClose }: EditorModalProps) {
  const [query, setQuery] = useState<string>('');
  const [
    { sections, editAction: action, editingSectionIndex, editingSubsectionIndex },
    dispatch,
  ] = useSectionEditor();

  const editingSection = sections[editingSectionIndex ?? -1] ?? null;
  const editingSubsection =
    sections[editingSectionIndex ?? -1]?.contents[editingSubsectionIndex ?? -1] ?? null;

  function handleClose() {
    setQuery('');
    onClose();
  }

  const foundMediaItems = mediaItems.filter((mediaItem) => mediaItem.title.indexOf(query) !== -1);

  return (
    <Modal isOpen={isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          pt="8"
          pb="6"
          px="8"
          textStyle="subtitle"
          color="text.light1"
          borderBottom="1px solid"
          borderColor="gray.light.95"
        >
          <Flex justifyContent="space-between" alignItems="center">
            <Icon boxSize={6} opacity={0} />
            <Box>{EDIT_MODAL_HEADER_LABEL[action]}</Box>
            <IconButton
              size="xs"
              variant="unstyled"
              aria-label="close"
              icon={<SmallCloseIcon boxSize={6} color="gray.light.65" />}
              onClick={handleClose}
            />
          </Flex>
        </ModalHeader>
        <ModalBody px={8} pt={6} pb={8}>
          {action === 'CREATE_SECTION' && (
            <CreateSectionForm
              onSubmit={(data) => {
                dispatch({ type: 'createSection', payload: { title: data.title } });
                handleClose();
              }}
            />
          )}
          {action === 'EDIT_SECTION' && (
            <EditSectionForm
              initialValue={{ title: editingSection?.title ?? '' }}
              onSubmit={(data) => {
                dispatch({ type: 'editSection', payload: { title: data.title } });
                handleClose();
              }}
            />
          )}
          {action === 'DELETE_SECTION' && (
            <Box>
              <Text mb={6} textStyle="body1.medium" color="text.light1" textAlign="center">
                ภายในบทเรียนนี้ยังมีข้อมูลหน่วยย่อยอยู่
                <br />
                แน่ใจว่าจะลบใช่หรือไม่
              </Text>
              <VStack spacing={4}>
                <Button
                  width="full"
                  colorScheme="red"
                  textStyle="body1.default"
                  borderRadius="100px"
                  onClick={() => {
                    dispatch({ type: 'deleteSection' });
                    onClose();
                  }}
                >
                  ใช่ ลบเลย
                </Button>
                <Button
                  width="full"
                  variant="outline"
                  colorScheme="brand"
                  textStyle="body1.default"
                  borderRadius="100px"
                  onClick={handleClose}
                >
                  ขอคิดอีกครั้ง
                </Button>
              </VStack>
            </Box>
          )}
          {action === 'CREATE_SUBSECTION' && (
            <CreateSubsectionForm
              onSubmit={({ title, isAutoPrefix }) => {
                dispatch({
                  type: 'createSubsection',
                  payload: { title, isAutoPrefix },
                });
                handleClose();
              }}
            />
          )}
          {action === 'EDIT_SUBSECTION' && (
            <EditSubsectionForm
              initialValue={{
                title: editingSubsection?.title ?? '',
                isAutoPrefix: editingSubsection?.autoPrefix ?? true,
              }}
              onSubmit={({ title, isAutoPrefix }) => {
                dispatch({
                  type: 'editSubsection',
                  payload: {
                    title,
                    isAutoPrefix,
                  },
                });
                handleClose();
              }}
            />
          )}
          {action === 'DELETE_SUBSECTION' && (
            <Box>
              <Text mb={6} textStyle="body1.medium" color="text.light1" textAlign="center">
                ภายในหน่วยย่อยนี้ยังมีข้อมูลสื่อต่างๆ อยู่
                <br />
                แน่ใจว่าจะลบใช่หรือไม่
              </Text>
              <VStack spacing={4}>
                <Button
                  width="full"
                  colorScheme="red"
                  textStyle="body1.default"
                  borderRadius="100px"
                  onClick={() => {
                    dispatch({ type: 'deleteSubsection' });
                    onClose();
                  }}
                >
                  ใช่ ลบเลย
                </Button>
                <Button
                  width="full"
                  variant="outline"
                  colorScheme="brand"
                  textStyle="body1.default"
                  borderRadius="100px"
                  onClick={handleClose}
                >
                  ขอคิดอีกครั้ง
                </Button>
              </VStack>
            </Box>
          )}
          {action === 'ADD_MEDIA_ITEM' && (
            <>
              <SearchMediaItemForm onSubmit={({ query }) => setQuery(query)} />
              <Box
                mt={8}
                p={4}
                background="gray.light.95"
                border="1px solid"
                borderColor="gray.light.85"
                borderRadius="8px"
              >
                {!query && (
                  <Center minHeight="220px">
                    <Text textAlign="center" textStyle="body1.medium" color="text.light2">
                      พิมพ์ชื่อสื่อในช่องค้นหา
                      <br />
                      เพื่อค้นหาสื่อที่คุณต้องการ
                    </Text>
                  </Center>
                )}
                {query && (
                  <>
                    {foundMediaItems.length === 0 ? (
                      <Center minHeight="220px">
                        <Text textAlign="center" textStyle="body1.medium" color="text.light2">
                          ไม่พบผลลัพธ์ "{query}"
                        </Text>
                      </Center>
                    ) : (
                      <VStack spacing={4} align="stretch">
                        {mediaItems
                          .filter((mediaItem) => mediaItem.title.indexOf(query) !== -1)
                          .map((mediaItem) => (
                            <Flex
                              key={`mediaItem-${mediaItem.id}`}
                              p={4}
                              direction="column"
                              background="white"
                              borderRadius="8px"
                              border="1px solid"
                              borderColor="gray.light.85"
                            >
                              <VStack spacing={4} alignSelf="flex-start" alignItems="flex-start">
                                <Heading as="h5" textStyle="h5" color="text.light1">
                                  {mediaItem.title}
                                </Heading>
                                <Tag
                                  px={4}
                                  py={1}
                                  textStyle="body2.default"
                                  borderRadius="100px"
                                  backgroundColor={`tag.${mediaItem.category.toLowerCase()}`}
                                  color={'white'}
                                >
                                  {mapCategoryToLabel(mediaItem.category)}
                                </Tag>
                              </VStack>
                              <Button
                                size="custom"
                                mt={2}
                                px={4}
                                py="10px"
                                alignSelf="flex-end"
                                colorScheme="brand"
                                textStyle="body2.medium"
                                borderRadius="100px"
                                onClick={() =>
                                  dispatch({
                                    type: 'addMediaItem',
                                    payload: {
                                      mediaItemId: mediaItem.id,
                                    },
                                  })
                                }
                              >
                                เลือกสื่อนี้
                              </Button>
                            </Flex>
                          ))}
                      </VStack>
                    )}
                  </>
                )}
              </Box>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
