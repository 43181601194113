import type { Category } from './types';

export const CATEGORY_LABEL: Record<Category, string> = {
  MANUAL: 'คู่มือครู',
  VIDEO: 'วีดิทัศน์',
  PPT: 'PowerPoint',
  PDF: 'PDF',
  WORD: 'Word',
  EXERCISE: 'แบบฝึกหัด',
  OTHER: 'อื่น ๆ',
};

export function mapCategoryToLabel(category: Category): string {
  return CATEGORY_LABEL[category];
}

export function getContents(sections: any[] | undefined): { type: 'MEDIA_ITEM'; id: number }[] {
  return (
    sections?.flatMap((content) => {
      if (content.type === 'GROUP') {
        return getContents(content.contents);
      } else {
        return content;
      }
    }) ?? []
  );
}
