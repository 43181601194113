import { mode, transparentize } from '@chakra-ui/theme-tools';

type Dict = Record<string, any>;

function variantGhost(props: Dict) {
  const { colorScheme: c, theme } = props;

  if (c === 'gray') {
    return {
      color: mode(`inherit`, `whiteAlpha.900`)(props),
      _hover: {
        bg: mode(`gray.100`, `whiteAlpha.200`)(props),
      },
      _active: { bg: mode(`gray.200`, `whiteAlpha.300`)(props) },
    };
  }

  const darkHoverBg = transparentize(`${c}.200`, 0.12)(theme);
  const darkActiveBg = transparentize(`${c}.200`, 0.24)(theme);

  return {
    color: mode(`${c}.600`, `${c}.200`)(props),
    bg: 'transparent',
    _hover: {
      bg: mode(`${c}.50`, darkHoverBg)(props),
    },
    _active: {
      bg: mode(`${c}.100`, darkActiveBg)(props),
    },
  };
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props;
  const borderColor = mode(`gray.200`, `whiteAlpha.300`)(props);

  if (c === 'brand') {
    return {
      border: '1px solid',
      borderColor: c === 'gray' ? borderColor : 'currentColor',
      color: mode(`${c}.45`, `${c}.200`)(props),
      bg: 'transparent',
      _hover: {
        bg: `gray.light.95`,
        borderColor: `${c}.65`,
      },
      _active: {
        color: `${c}.35`,
        borderColor: `${c}.35`,
      },
    };
  } else {
    return {
      border: '1px solid',
      borderColor: c === 'gray' ? borderColor : 'currentColor',
      ...variantGhost(props),
    };
  }
}

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

function variantSolid(props: Dict) {
  const { colorScheme: c } = props;

  if (c === 'gray') {
    const bg = mode(`gray.100`, `whiteAlpha.200`)(props);

    return {
      bg,
      _hover: {
        bg: mode(`gray.200`, `whiteAlpha.300`)(props),
        _disabled: {
          bg,
        },
      },
      _active: { bg: mode(`gray.300`, `whiteAlpha.400`)(props) },
    };
  }

  const { bg = `${c}.500`, color = 'white', hoverBg = `${c}.600`, activeBg = `${c}.700` } =
    accessibleColorMap[c] || {};

  const background = mode(bg, `${c}.200`)(props);

  return {
    bg: background,
    color: mode(color, `gray.800`)(props),
    _hover: {
      bg: mode(hoverBg, `${c}.300`)(props),
      _disabled: {
        opacity: c === 'brand' ? 1 : undefined,
        bg: c === 'brand' ? '#CCCCCC' : background,
      },
    },
    _disabled: {
      opacity: c === 'brand' ? 1 : undefined,
      bg: c === 'brand' ? '#CCCCCC' : background,
    },
    _active: { bg: mode(activeBg, `${c}.400`)(props) },
  };
}

const button = {
  variants: {
    outline: variantOutline,
    solid: variantSolid,
  },
};

export default button;
