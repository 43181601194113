import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ViewIcon',
  viewBox: '0 0 96 96',
  path: [
    <path
      key="1"
      d="M19.6667 47.9435C19.6667 47.9435 29.9327 31 47.8753 31C65.8179 31 76.3334 47.9435 76.3334 47.9435C76.3334 47.9435 66.8043 65 47.8753 65C28.9463 65 19.6667 47.9435 19.6667 47.9435Z"
      stroke="currentColor"
      strokeWidth="4"
      strokeLinejoin="round"
    />,
    <path
      key="2"
      d="M48 57C52.9706 57 57 52.9706 57 48C57 43.0294 52.9706 39 48 39C43.0294 39 39 43.0294 39 48C39 52.9706 43.0294 57 48 57Z"
      fill="currentColor"
    />,
    <path
      key="3"
      d="M47.9999 55.4494C52.1138 55.4494 55.4487 52.1144 55.4487 48.0006C55.4487 43.8867 52.1138 40.5518 47.9999 40.5518C43.886 40.5518 40.5511 43.8867 40.5511 48.0006C40.5511 52.1144 43.886 55.4494 47.9999 55.4494Z"
      fill="currentColor"
    />,
  ],
});
