import { Dispatch, useReducer, createContext, PropsWithChildren, useContext } from 'react';
import { Section } from '../types';

export type EditAction =
  | 'CREATE_SECTION'
  | 'EDIT_SECTION'
  | 'DELETE_SECTION'
  | 'CREATE_SUBSECTION'
  | 'EDIT_SUBSECTION'
  | 'DELETE_SUBSECTION'
  | 'ADD_MEDIA_ITEM';

type State = {
  editAction: EditAction;
  editingSectionIndex: number | null;
  editingSubsectionIndex: number | null;
  sections: Section[];
  isInitialized: boolean;
  isChangesCopied: boolean;
};

type Action =
  | { type: 'initSections'; payload: { sections: Section[] } }
  | {
      type: 'startEditing';
      payload: { action: EditAction; sectionIndex?: number; subsectionIndex?: number };
    }
  | { type: 'setStartNo'; payload: { startNo: number } }
  | { type: 'createSection'; payload: { title: string } }
  | { type: 'editSection'; payload: { title: string } }
  | { type: 'deleteSection' }
  | { type: 'moveSectionUp'; payload: { sectionIndex: number } }
  | { type: 'moveSectionDown'; payload: { sectionIndex: number } }
  | { type: 'createSubsection'; payload: { title: string; isAutoPrefix: boolean } }
  | { type: 'editSubsection'; payload: { title: string; isAutoPrefix: boolean } }
  | { type: 'deleteSubsection' }
  | { type: 'moveSubsectionUp'; payload: { sectionIndex: number; subsectionIndex: number } }
  | { type: 'moveSubsectionDown'; payload: { sectionIndex: number; subsectionIndex: number } }
  | {
      type: 'addMediaItem';
      payload: { mediaItemId: number };
    }
  | {
      type: 'removeMediaItem';
      payload: { mediaItemId: number; sectionIndex: number; subsectionIndex: number };
    }
  | {
      type: 'moveMediaItemUp';
      payload: { mediaItemIndex: number; sectionIndex: number; subsectionIndex: number };
    }
  | {
      type: 'moveMediaItemDown';
      payload: { mediaItemIndex: number; sectionIndex: number; subsectionIndex: number };
    }
  | {
      type: 'copy';
    };

function sectionEditorReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'initSections':
      return {
        ...state,
        sections: action.payload.sections ?? [],
        isInitialized: true,
      };
    case 'startEditing':
      return {
        ...state,
        editAction: action.payload.action,
        editingSectionIndex: action.payload.sectionIndex ?? null,
        editingSubsectionIndex: action.payload.subsectionIndex ?? null,
      };
    case 'setStartNo':
      const [firstSection, ...restSections] = state.sections;
      return {
        ...state,
        isChangesCopied: false,
        sections: [
          {
            ...firstSection,
            start: action.payload.startNo,
          },
          ...restSections,
        ],
      };
    case 'createSection':
      return {
        ...state,
        isChangesCopied: false,
        sections: [
          ...state.sections,
          {
            type: 'GROUP',
            title: action.payload.title,
            contents: [],
          },
        ],
      };
    case 'editSection':
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, index) => {
          if (index !== state.editingSectionIndex) {
            return section;
          } else {
            return {
              ...section,
              title: action.payload.title,
            };
          }
        }),
      };
    case 'deleteSection':
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.filter((_, index) => index !== state.editingSectionIndex),
      };
    case 'moveSectionUp':
      if (action.payload.sectionIndex === 0) {
        // Do nothing because the moving section is already at the top
        return state;
      } else {
        const movedUpSection = state.sections[action.payload.sectionIndex];
        const movedDownSection = state.sections[action.payload.sectionIndex - 1];
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, index) => {
            if (index === action.payload.sectionIndex) {
              return movedDownSection;
            } else if (index === action.payload.sectionIndex - 1) {
              return movedUpSection;
            } else {
              return section;
            }
          }),
        };
      }
    case 'moveSectionDown':
      if (action.payload.sectionIndex === state.sections.length - 1) {
        // Do nothing because the moving section is already at the bottom
        return state;
      } else {
        const movedUpSection = state.sections[action.payload.sectionIndex + 1];
        const movedDownSection = state.sections[action.payload.sectionIndex];
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, index) => {
            if (index === action.payload.sectionIndex) {
              return movedUpSection;
            } else if (index === action.payload.sectionIndex + 1) {
              return movedDownSection;
            } else {
              return section;
            }
          }),
        };
      }
    case 'createSubsection': {
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, index) => {
          if (index !== state.editingSectionIndex) {
            return section;
          } else {
            return {
              ...section,
              contents: [
                ...section.contents,
                {
                  type: 'GROUP',
                  title: action.payload.title,
                  contents: [],
                  autoPrefix: action.payload.isAutoPrefix,
                },
              ],
            };
          }
        }),
      };
    }
    case 'editSubsection': {
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, sectionIndex) => {
          if (sectionIndex !== state.editingSectionIndex) {
            return section;
          } else {
            return {
              ...section,
              contents: section.contents.map((subsection, subsectionIndex) => {
                if (subsectionIndex !== state.editingSubsectionIndex) {
                  return subsection;
                } else {
                  return {
                    ...subsection,
                    title: action.payload.title,
                    autoPrefix: action.payload.isAutoPrefix,
                  };
                }
              }),
            };
          }
        }),
      };
    }
    case 'deleteSubsection': {
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, sectionIndex) => {
          if (sectionIndex !== state.editingSectionIndex) {
            return section;
          } else {
            return {
              ...section,
              contents: section.contents.filter(
                (_, subsectionIndex) => subsectionIndex !== state.editingSubsectionIndex
              ),
            };
          }
        }),
      };
    }
    case 'moveSubsectionUp':
      if (action.payload.subsectionIndex === 0) {
        // Do nothing because the moving subsection is already at the top
        return state;
      } else {
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, sectionIndex) => {
            if (sectionIndex === action.payload.sectionIndex) {
              const movedUpSubsection = section.contents[action.payload.subsectionIndex];
              const movedDownSubsection = section.contents[action.payload.subsectionIndex - 1];
              return {
                ...section,
                contents: section.contents.map((subsection, subsectionIndex) => {
                  if (subsectionIndex === action.payload.subsectionIndex) {
                    return movedDownSubsection;
                  } else if (subsectionIndex === action.payload.subsectionIndex - 1) {
                    return movedUpSubsection;
                  } else {
                    return subsection;
                  }
                }),
              };
            } else {
              return section;
            }
          }),
        };
      }
    case 'moveSubsectionDown':
      if (
        action.payload.subsectionIndex ===
        state.sections[action.payload.sectionIndex].contents.length - 1
      ) {
        // Do nothing because the moving subsection is already at the bottom
        return state;
      } else {
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, sectionIndex) => {
            if (sectionIndex === action.payload.sectionIndex) {
              const movedDownSubsection = section.contents[action.payload.subsectionIndex];
              const movedUpSubsection = section.contents[action.payload.subsectionIndex + 1];
              return {
                ...section,
                contents: section.contents.map((subsection, subsectionIndex) => {
                  if (subsectionIndex === action.payload.subsectionIndex) {
                    return movedUpSubsection;
                  } else if (subsectionIndex === action.payload.subsectionIndex + 1) {
                    return movedDownSubsection;
                  } else {
                    return subsection;
                  }
                }),
              };
            } else {
              return section;
            }
          }),
        };
      }
    case 'addMediaItem': {
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, sectionIndex) => {
          if (sectionIndex !== state.editingSectionIndex) {
            return section;
          } else {
            return {
              ...section,
              contents: section.contents.map((subsection, subsectionIndex) => {
                if (subsectionIndex !== state.editingSubsectionIndex) {
                  return subsection;
                } else {
                  return {
                    ...subsection,
                    contents: [
                      ...subsection.contents,
                      {
                        type: 'MEDIA_ITEM',
                        id: action.payload.mediaItemId,
                      },
                    ],
                  };
                }
              }),
            };
          }
        }),
      };
    }
    case 'removeMediaItem': {
      return {
        ...state,
        isChangesCopied: false,
        sections: state.sections.map((section, sectionIndex) => {
          if (sectionIndex !== action.payload.sectionIndex) {
            return section;
          } else {
            return {
              ...section,
              contents: section.contents.map((subsection, subsectionIndex) => {
                if (subsectionIndex !== action.payload.subsectionIndex) {
                  return subsection;
                } else {
                  return {
                    ...subsection,
                    contents: subsection.contents.filter(
                      ({ id }) => id !== action.payload.mediaItemId
                    ),
                  };
                }
              }),
            };
          }
        }),
      };
    }
    case 'moveMediaItemUp': {
      if (action.payload.mediaItemIndex === 0) {
        // Do nothing because the moving media item is already at the top
        return state;
      } else {
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, sectionIndex) => {
            if (sectionIndex !== action.payload.sectionIndex) {
              return section;
            } else {
              return {
                ...section,
                contents: section.contents.map((subsection, subsectionIndex) => {
                  if (subsectionIndex !== action.payload.subsectionIndex) {
                    return subsection;
                  } else {
                    const movedUpMediaItem = subsection.contents[action.payload.mediaItemIndex];
                    const movedDownMediaItem =
                      subsection.contents[action.payload.mediaItemIndex - 1];
                    return {
                      ...subsection,
                      contents: subsection.contents.map((mediaItem, mediaItemIndex) => {
                        if (mediaItemIndex === action.payload.mediaItemIndex) {
                          return movedDownMediaItem;
                        } else if (mediaItemIndex === action.payload.mediaItemIndex - 1) {
                          return movedUpMediaItem;
                        } else {
                          return mediaItem;
                        }
                      }),
                    };
                  }
                }),
              };
            }
          }),
        };
      }
    }
    case 'moveMediaItemDown': {
      if (
        action.payload.mediaItemIndex ===
        state.sections[action.payload.sectionIndex].contents[action.payload.subsectionIndex]
          .contents.length -
          1
      ) {
        // Do nothing because the moving media item is already at the bottom
        return state;
      } else {
        return {
          ...state,
          isChangesCopied: false,
          sections: state.sections.map((section, sectionIndex) => {
            if (sectionIndex !== action.payload.sectionIndex) {
              return section;
            } else {
              return {
                ...section,
                contents: section.contents.map((subsection, subsectionIndex) => {
                  if (subsectionIndex !== action.payload.subsectionIndex) {
                    return subsection;
                  } else {
                    const movedDownMediaItem = subsection.contents[action.payload.mediaItemIndex];
                    const movedUpMediaItem = subsection.contents[action.payload.mediaItemIndex + 1];
                    return {
                      ...subsection,
                      contents: subsection.contents.map((mediaItem, mediaItemIndex) => {
                        if (mediaItemIndex === action.payload.mediaItemIndex) {
                          return movedUpMediaItem;
                        } else if (mediaItemIndex === action.payload.mediaItemIndex + 1) {
                          return movedDownMediaItem;
                        } else {
                          return mediaItem;
                        }
                      }),
                    };
                  }
                }),
              };
            }
          }),
        };
      }
    }
    case 'copy': {
      return {
        ...state,
        isChangesCopied: true,
      };
    }
  }
}

const EditorStateContext = createContext<State | undefined>(undefined);
const EditorDispathContext = createContext<Dispatch<Action> | undefined>(undefined);

export function SectionEditorProvider({ children }: PropsWithChildren<{}>) {
  const [state, dispatch] = useReducer(sectionEditorReducer, {
    editAction: 'CREATE_SECTION',
    editingSectionIndex: null,
    editingSubsectionIndex: null,
    sections: [],
    isInitialized: false,
    isChangesCopied: true,
  });

  return (
    <EditorStateContext.Provider value={state}>
      <EditorDispathContext.Provider value={dispatch}>{children}</EditorDispathContext.Provider>
    </EditorStateContext.Provider>
  );
}

export default function useSectionEditor(): [State, Dispatch<Action>] {
  const state = useContext(EditorStateContext);
  if (state === undefined) {
    throw new Error('useSectionEditor must be used within a SectionEditorProvider');
  }

  const dispatch = useContext(EditorDispathContext);
  if (dispatch === undefined) {
    throw new Error('useSectionEditor must be used within a SectionEditorProvider');
  }

  return [state, dispatch];
}
