import { createIcon } from '@chakra-ui/react';

export default createIcon({
  displayName: 'ArrowUpIcon',
  viewBox: '0 0 16 16',
  path: [
    <path
      key="1"
      d="M2 11L8 5L14 11"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ],
});
