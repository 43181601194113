const colors = {
  brand: {
    // Figma Ref.
    35: '#0B58A5',
    45: '#0E73D7',
    55: '#288CF1',
    65: '#58A6F4',
    // Chakra Ref.
    500: '#0E73D7', // normal
    600: '#58A6F4', // hover
    700: '#0B58A5', // active
  },
  gray: {
    light: {
      95: '#F2F2F2',
      85: '#D9D9D9',
      75: '#BFBFBF',
      65: '#A6A6A6',
    },
    dark: {
      15: '#262626',
      25: '#404040',
      35: '#595959',
    },
  },
  businessBlue: {
    80: '#B3BBDB',
  },
  red: {
    // FIXME: this is a workaround to make Chakra Input use the `system.error` color in their invalid state
    500: '#EB5757',
  },
  text: {
    light1: '#231F20',
    light2: '#787878',
    dark1: '#EEECED',
  },
  tag: {
    manual: '#214F5C',
    video: '#FAA919',
    ppt: '#ED6C47',
    word: '#2B7CD3',
    pdf: '#CC4B4C',
    exercise: '#8753DB',
    other: '#00CA2C',
  },
  system: {
    error: '#EB5757',
  },
  menuItem: {
    hover: '#DEEDFD',
  },
};

export default colors;
