import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './global.css';
import './fonts/Anuphan/Anuphan-Medium.eot';
import './fonts/Anuphan/Anuphan-Medium.otf';
import './fonts/Anuphan/Anuphan-Medium.svg';
import './fonts/Anuphan/Anuphan-Medium.woff';
import './fonts/Anuphan/Anuphan-Medium.woff2';

import './fonts/Anuphan/Anuphan-Regular.eot';
import './fonts/Anuphan/Anuphan-Regular.otf';
import './fonts/Anuphan/Anuphan-Regular.svg';
import './fonts/Anuphan/Anuphan-Regular.ttf';
import './fonts/Anuphan/Anuphan-Regular.woff';
import './fonts/Anuphan/Anuphan-Regular.woff2';

import './fonts/Anuphan/Anuphan-SemiBold.eot';
import './fonts/Anuphan/Anuphan-SemiBold.otf';
import './fonts/Anuphan/Anuphan-SemiBold.svg';
import './fonts/Anuphan/Anuphan-SemiBold.ttf';
import './fonts/Anuphan/Anuphan-SemiBold.woff';
import './fonts/Anuphan/Anuphan-SemiBold.woff2';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
