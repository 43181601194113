import { Box, Button, FormControl, FormErrorMessage, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';

type FormValue = {
  title: string;
};

interface EditSectionFormProps {
  initialValue: FormValue;
  onSubmit: (data: { title: string }) => void;
}

export default function EditSectionForm({ initialValue, onSubmit }: EditSectionFormProps) {
  const { register, formState, errors, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      title: initialValue.title,
    },
  });

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl textStyle="body2.default" isInvalid={errors?.title !== undefined}>
        <Input
          ref={register({
            validate: (value) => value.length > 0 || 'กรุณากรอกชื่อบทเรียน',
            setValueAs: (value) => value.trim(),
          })}
          name="title"
          type="text"
          required
          borderRadius="100px"
          borderColor="gray.light.95"
          placeholder="กรุณากรอกชื่อบทเรียน"
          _placeholder={{ color: 'gray.light.65' }}
        />
        <FormErrorMessage>{errors?.title?.message}</FormErrorMessage>
      </FormControl>
      <Button
        type="submit"
        isLoading={formState.isSubmitting}
        width="full"
        mt={8}
        colorScheme="brand"
        textStyle="body1.default"
        borderRadius="100px"
      >
        แก้ไข
      </Button>
    </Box>
  );
}
