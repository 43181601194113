import { extendTheme } from '@chakra-ui/react';
import colors from './colors';
import sizes from './sizes';
import styles from './styles';
import textStyles from './textStyles';
import Button from './components/button';

const overrides = {
  colors,
  styles,
  textStyles,
  sizes,
  space: {
    ...sizes,
  },
  components: {
    Button,
  },
};

// @ts-ignore Ignore because `gray.light.95` is not compatible with the type but it does work in application
export default extendTheme(overrides);
