import { Flex, FlexProps } from '@chakra-ui/react';

export default function Card(props: FlexProps) {
  const {
    bg = 'white',
    borderRadius = '10px',
    boxShadow = '0px 10px 20px rgba(0, 0, 0, 0.02)',
    transition = 'box-shadow 0.1s ease-out, transform 0.1s ease-out, opacity 0.1s ease-out',
    cursor = 'pointer',
    _hover = {
      boxShadow: '0px 15px 25px rgba(0, 0, 0, 0.08)',
      transform: 'translateY(-10px)',
      willChange: 'transform, opacity, box-shadow',
    },
    _active = {
      boxShadow: '0 2px 20px 0 rgba(0, 0, 0, 0.1)',
      willChange: 'transform, opacity, box-shadow',
    },
    overflow = 'hidden',
    ...restProps
  } = props;

  return (
    <Flex
      cursor={cursor}
      // @ts-ignore
      css={{
        '.content-wrapper': {
          transition: transition,
        },
        '&:hover > .content-wrapper': _hover,
        '&:active > .content-wrapper': _active,
      }}
    >
      <Flex
        flexGrow={1}
        bg={bg}
        borderRadius={borderRadius}
        boxShadow={boxShadow}
        overflow={overflow}
        className="content-wrapper"
        {...restProps}
      >
        {props.children}
      </Flex>
    </Flex>
  );
}
