import { CopyIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Link,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Spacer,
  Spinner,
  Text,
  ToastId,
  useClipboard,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import useSectionEditor from '../Hook/useSectionEditor';
import type { Basket } from '../types';
import SectionAccordion from '../Component/SectionAccordion';
import { getContents } from '../utils';
import EditorModal from '../Component/EditorModal';

const CONTAINER_WIDTH = { base: '1112px', md: '1144px' };

function fetchBasketBySlug(slug: string) {
  console.log(`APP calling 1: ${process.env.REACT_APP_API_URL}/baskets?_where[0][slug]=${encodeURIComponent(slug)}&_limit=1`)
  console.log(`APP calling 2: ${process.env.API_URL}/baskets?_where[0][slug]=${encodeURIComponent(slug)}&_limit=1`)
  console.log(`test: ${process.env.REACT_APP_TEST}`)

  return fetch(
    `${process.env.REACT_APP_API_URL}/baskets?_where[0][slug]=${encodeURIComponent(slug)}&_limit=1`
  )
    .then((res) => res.json())
    .then(([basket]) => basket)
    .catch((error) => {
      // Log the error to the console
      console.error('Fetch error:', error);
      // Re-throw the error if needed for further handling
      throw error;
    });
}

interface SetStartNoFormProps {
  initialValue: {
    startNo: number;
  };
  onSubmit: (data: { startNo: number }) => void;
}

function SetStartNoForm({ initialValue, onSubmit }: SetStartNoFormProps) {
  const { register, handleSubmit } = useForm<{
    startNo: number;
  }>({
    defaultValues: {
      startNo: initialValue.startNo,
    },
  });
  return (
    <Flex as="form" onSubmit={handleSubmit(onSubmit)}>
      <FormControl display="flex" alignItems="center" flexShrink={1}>
        <FormLabel my={0} mr={6} flex="0 0 auto" textStyle="subtitle" color="text.light1">
          เริ่มจากบทที่
        </FormLabel>
        <NumberInput min={1}>
          <NumberInputField
            ref={register({
              valueAsNumber: true,
            })}
            type="number"
            name="startNo"
            inputMode="decimal"
            textStyle="body1.default"
            borderColor="gray.light.95"
            placeholder="กรอกเลขบทเริ่มต้น"
            _placeholder={{ color: 'gray.light.65' }}
          />
          <NumberInputStepper>
            <NumberIncrementStepper />
            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
      </FormControl>
      <Button
        ml={6}
        type="submit"
        colorScheme="brand"
        textStyle="body2.default"
        borderRadius="100px"
        variant="outline"
      >
        เปลี่ยน
      </Button>
    </Flex>
  );
}

function CopyToast({ toastId, basketId }: { toastId: ToastId; basketId: number }) {
  return (
    <Flex
      key={toastId}
      px={6}
      py={4}
      width="100%"
      background="gray.dark.15"
      borderRadius="8px"
      boxShadow="0px 5px 20px rgba(0, 0, 0, 0.25)"
      align="center"
    >
      <Text as="div" textStyle="body1.default" color="text.dark1">
        คัดลอกข้อมูลแล้ว กรุณากลับสู่ Strapi และวางข้อมูลในส่วน Sections
      </Text>
      <Spacer ml={4} />
      <Link
        whiteSpace="nowrap"
        href={`${process.env.REACT_APP_API_URL}/admin/plugins/content-manager/collectionType/application::baskets.baskets/${basketId}`}
        textStyle="body1.default"
        color="brand.65"
        _hover={{
          textDecoration: 'none',
        }}
        _active={{
          textDecoration: 'none',
        }}
      >
        กลับสู่ Strapi
      </Link>
    </Flex>
  );
}

export default function Editor() {
  const { slug } = useParams<{ slug: string }>();
  const [{ sections, isInitialized, isChangesCopied }, dispatch] = useSectionEditor();
  const { onCopy } = useClipboard(JSON.stringify(sections, null, 2));
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: basket } = useQuery<Basket>(['basket', slug], () => fetchBasketBySlug(slug), {});

  useEffect(() => {
    if (basket && !isInitialized) {
      dispatch({
        type: 'initSections',
        payload: {
          sections: basket?.sections,
        },
      });
    }
  }, [basket, dispatch, isInitialized]);

  useEffect(
    function showConfirmationPromptBeforeExit() {
      function handleBeforeUnloadEvent(e: BeforeUnloadEvent) {
        if (!isChangesCopied) {
          // Cancel the event
          e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
          // Chrome requires returnValue to be set
          e.returnValue = 'Are you sure you want to leave without copying the changes';

          return 'Are you sure you want to leave without copying the changes?';
        }
      }

      window.addEventListener('beforeunload', handleBeforeUnloadEvent);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
      };
    },
    [isChangesCopied]
  );

  const addedMediaItems = new Set(getContents(sections).map(({ id }) => id));

  return (
    <Box as="main" background="#FAFAFA">
      <EditorModal
        mediaItems={basket?.media_items?.filter(({ id }) => !addedMediaItems.has(id)) ?? []}
        isOpen={isOpen}
        onClose={onClose}
      />
      {!isInitialized ? (
        <Center minHeight="100vh">
          <Spinner
            size="xl"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="brand.500"
          />
        </Center>
      ) : (
        <>
          <Center as="section" pt={16} pb={12} background="white">
            <Heading as="h2" textStyle="h2" color="text.light1">
              {basket?.title}
            </Heading>
          </Center>
          <Container my={16} maxWidth={CONTAINER_WIDTH} px={{ base: 4, md: 8 }} py={4}>
            <Heading as="h3" textStyle="h3" color="text.light1">
              จัดสื่อประกอบการสอน
            </Heading>
            <Flex mt={8}>
              <SetStartNoForm
                initialValue={{ startNo: sections[0]?.start ?? 1 }}
                onSubmit={({ startNo }) =>
                  dispatch({
                    type: 'setStartNo',
                    payload: {
                      startNo,
                    },
                  })
                }
              />
              <Spacer />
              <Button
                leftIcon={<CopyIcon />}
                colorScheme="brand"
                textStyle="body1.default"
                borderRadius="100px"
                onClick={() => {
                  onCopy();
                  dispatch({ type: 'copy' });
                  toast({
                    position: 'top',
                    duration: 3000,
                    render: ({ id }) => <CopyToast toastId={id} basketId={basket?.id!} />,
                  });
                }}
              >
                คัดลอกข้อมูล
              </Button>
            </Flex>
            <SectionAccordion basket={basket} onOpen={onOpen} />
          </Container>
        </>
      )}
    </Box>
  );
}
